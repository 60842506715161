import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
// Material UI Kit
import GridContainer from "../components/Grid/GridContainer"
import Card from "../components/Card/Card"
import CardHeader from "../components/Card/CardHeader"
import GridItem from "../components/Grid/GridItem"
// Styles
import withStyles from "@material-ui/core/styles/withStyles"
import javascriptStyles from "../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
    alignItems: "center",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
  message: {
    padding: "40px 90px",
    color: "#626f78",
  },
}

const PrivacyPage = props => {
  const { classes } = props
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <GridContainer
        spacing={0}
        direction="column"
        alignItems="center"
        alignContent="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Card style={style.cardBorder}>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h5 className={classes.cardTitleWhite}>Privacy Policy</h5>
          </CardHeader>
          <GridItem xs={12} sm={10} style={style.message}>
            <p>
              <strong>Published on</strong>: October 30, 2019
            </p>
            <p>
              Center Consoles Only is committed to protecting the privacy of
              visitors to our website, subscribers to our newsletters, and those
              providing contact information. In addition, Center Consoles Only
              is opposed to unauthorized commercial e-mail delivery, otherwise
              known as spam. Please read the following Privacy and Spam Policy
              to understand how your personal information is treated. From time
              to time, Center Consoles Only may offer new information or
              services that may affect this policy. If you have any questions or
              concerns regarding this Privacy and Spam Policy, please send an
              e-mail to{" "}
              <a href="mailto:support@centerconsolesonly.net">
                support@centerconsolesonly.net
              </a>
              . Spam abuses can be notified at{" "}
              <a href="mailto:support@centerconsolesonly.net">
                support@centerconsolesonly.net
              </a>
              .
            </p>
            <p>
              <b>WHAT INFORMATION DO WE COLLECT &amp; HOW IS IT USED?</b>
            </p>
            <blockquote>
              <p>
                <strong>
                  Information You Voluntarily Submit to the Website
                </strong>
                : We may collect personal information from you such as your name
                or e-mail address. For example, you may voluntarily submit
                personal information to Center Consoles Only by leaving a
                comment, subscribing to a newsletter, or submitting a contact
                form. In addition, upon making certain purchases described
                herein, we may ask you to create a user profile, which would
                allow you to create a username and password. We will store the
                username, but your password will not be visible in our records.
              </p>
              <p>
                <strong>Information We Collect from Others</strong>: We may
                receive information about you from other sources. For example,
                if you use a third-party software through the site, they may
                transfer information to us for fulfillment.
              </p>
              <p>
                <strong>Automatically-Collected Information</strong>: We
                automatically collect certain information about you and the
                device with which you access Center Consoles Only. For example,
                when you use Center Consoles Only, we will log your IP address,
                operating system type, browser type, referring website, pages
                you viewed, and the dates/times when you accessed the website.
                We may also collect information about actions you take when
                using Center Consoles Only, such as links clicked.
              </p>
              <p>
                <strong>Cookies</strong>: We may log information using cookies,
                which are small data files stored on your browser by Social
                Media Examiner. We may use both session cookies, which expire
                when you close your browser, and persistent cookies, which stay
                on your browser until deleted, to provide you with a more
                personalized experience on the website.
              </p>
            </blockquote>
            <p>
              The following list includes examples of specific ways we collect
              personal information from our website visitors and the type of
              information collected:
            </p>
            <blockquote>
              <p>
                <strong>Electronic Newsletter</strong>: To register online for
                our electronic newsletters, we request the following required
                information: (i) name, and (ii) e-mail address. The provision of
                an e-mail address is mandatory in order to receive our
                newsletter.
              </p>
              <p>
                <strong>Contact Forms</strong>: To contact Center Consoles Only
                for the purposes of evaluating or engaging our services or to
                answer your questions, we utilize numerous forms throughout our
                site. Required information varies depending on the form and at
                most includes (i) name, and (ii) e-mail address. The provision
                of this information is mandatory in order to receive a response
                from Center Consoles Only. The provision of the remaining
                information requested on the registration form is optional.
              </p>
              <p>
                <strong>Online Purchases</strong>: When you purchase a product
                from Center Consoles Only, we may utilize a third-party online
                shopping system, which is described more fully below. To process
                your payment, certain standard required information includes
                address and credit card information. Transactions are secured
                via SSL encryption and credit card details will never be
                e-mailed. Credit information is either not stored or purged from
                our systems on a regular basis.
              </p>
            </blockquote>
            <p>
              <b>HOW YOUR PERSONAL INFORMATION MAY BE USED</b>
            </p>
            <p>
              Center Consoles Only may use your personal information in the
              following ways:
            </p>
            <ul>
              <li>To operate and maintain the website;</li>
              <li>
                To create your account, identify you as a user of the website,
                and customize the website to your account;
              </li>
              <li>
                To send you promotional information, such as newsletters. Each
                e-mail newsletter will provide information on how to opt-out of
                future mailings by unsubscribing;
              </li>
              <li>
                To send you administrative communications, such as
                administrative e-mails, confirmation e-mails, technical notices,
                updates on policies, or security alerts;
              </li>
              <li>To respond to your comments or inquiries;</li>
              <li>To provide you with user support;</li>
              <li>To track and measure website performance;</li>
              <li>
                To process payment for purchases you make through Social Media
                Examiner;
              </li>
              <li>
                To protect, investigate, and deter against unauthorized or
                illegal activity;
              </li>
              <li>
                To review the effectiveness of our marketing programs and
                analyze other general demographic trends;
              </li>
              <li>
                To notify you of new information or services that may be of
                interest to you; or,
              </li>
              <li>To send promotional materials.</li>
            </ul>
            <p>
              <b>DOES Center Consoles Only SHARE MY INFORMATION?</b>
            </p>
            <p>
              Center Consoles Only will not share, rent, or sell your personally
              identifiable information with third-parties. We may, however,
              share your information with third-parties when we are authorized
              to share such information.
            </p>
            <p>
              Additionally, Center Consoles Only may use third-party service
              providers to service various aspects of the website. Each
              third-party service provider’s use of your personal information is
              dictated by their respective privacy policy. Center Consoles Only
              currently uses the following third-party service providers:
            </p>
            <blockquote>
              <p>
                <strong>Tracking</strong>: Center Consoles Only uses various
                marketing tools that help us create a better user experience for
                people visiting our site. These tools allow us to look at
                aggregated data such as scrolling patterns, clicks, and allow us
                to run tests to determine which pages result in the best
                actions. However, your personally identifiable information is
                not used by any of these tools. We use the following third-party
                service providers for tracking:
              </p>
              <p>
                Hotjar:
                <a
                  href="https://www.hotjar.com/legal/policies/privacy"
                  className="external"
                  target="_blank"
                >
                  Privacy policy
                </a>
                ,
                <a
                  href="https://www.hotjar.com/legal/policies/cookie-information"
                  className="external"
                  target="_blank"
                >
                  cookie information
                </a>
              </p>
              <p>
                Google Analytics:
                <a
                  href="https://support.google.com/analytics/answer/6004245"
                  className="external"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </p>
              <p>
                <strong>Remarketing</strong>: Center Consoles Only uses
                remarketing tracking cookies and conversion pixels from vendors
                such as Google and Facebook to present special offers to you for
                our products or services over the Google Content Network and via
                social networks. This means you may see an ad for one of our
                products or services as a result of visiting our website. In
                addition, Center Consoles Only uses custom audiences based on
                e-mails and phone numbers of subscribers and customers. This
                allows us to present special offers for our products and
                services to you via Facebook and Google. However, your
                personally identifiable information is not used by any
                remarketing service other than to present you special offers
                from us. We use the following third-party service providers for
                remarketing:
              </p>
              <p>
                Facebook:
                <a
                  href="https://www.facebook.com/help/568137493302217"
                  className="external"
                  target="_blank"
                >
                  Opt-out of Facebook remarketing here
                </a>
              </p>
              <p>
                Google:
                <a
                  href="https://support.google.com/ads/answer/2662922?hl=en"
                  className="external"
                  target="_blank"
                >
                  Opt-out of Google remarketing here
                </a>
              </p>
              <p>
                <strong>Newsletters</strong>: Center Consoles Only uses Drip as
                its e-mail service provider. Drip collects contact information,
                distributes our newsletter, and tracks actions you take that
                assist us in measuring the performance of the website and
                newsletters. Upon subscription, Drip also tracks the pages you
                visit on the website. If you become a customer, information
                about the products you purchase is passed into Drip.
              </p>
              <p>
                Our newsletters contain tracking pixels. This pixel is embedded
                in e-mails and allows us to analyze the success of our
                newsletters. Because of these tracking pixels, we may see if and
                when you open an e-mail, which links within the e-mail you
                click, and the pages you visit on our website. This behavior is
                not passed to third-parties. All data submitted at the time of
                subscription to our newsletter is stored on Drip’s servers.{" "}
                <a
                  href="https://www.drip.com/privacy"
                  className="external"
                  target="_blank"
                >
                  You may access Drip’s privacy policy here
                </a>
                . At any time, you may be removed from our newsletter list by
                clicking on the unsubscribe button provided in each e-mail.
              </p>
              <p>
                We may also rely on a third-party service, OptinMonster, to
                service certain pop-up subscription boxes. Any information
                collected by OptinMonster is passed through directly to Drip and
                is not stored by any party other than Drip.
              </p>
              <p>
                <strong>Purchases</strong>: Center Consoles Only uses
                1ShoppingCart, Stripe, and PayPal to service payments for some
                services.
              </p>
              <p>
                <strong>
                  <em>• Shopify</em>
                </strong>{" "}
                collects personal information such as your name, e-mail address,
                address, and payment information. All data entered into Shopify
                is stored on Shopify’s site.{" "}
                <a
                  href="http://https://www.shopify.com/legal/privacy"
                  className="external"
                  target="_blank"
                >
                  Click here for their Privacy Policy
                </a>
              </p>
              <p>
                <strong>Surveys</strong>: Periodically, we may invite you to
                complete a voluntary survey. All surveys are completed through
                Survey Monkey. All survey responses may be submitted anonymously
                and only your IP address is stored. You may voluntarily enter
                your name and e-mail address. All data submitted through Survey
                Monkey is stored on their servers and is subject to the{" "}
                <a
                  href="https://www.surveymonkey.com/mp/legal/privacy-policy/"
                  className="external"
                  target="_blank"
                >
                  Survey Monkey privacy policy
                </a>
                .
              </p>
              <p>
                <strong>Contact Information and Website Support</strong>: We
                offer multiple ways to contact Center Consoles Only, including
                contact forms, e-mails, and phone numbers. If at any time, you
                attempt to contact us through a contact form, the information
                you provide will be stored on our servers. If you submit a form
                or email us, we use Netlify to process all these inquiries. All
                related information is stored on the servers at Netlify, in
                accordance with the&nbsp;
                <a
                  href="https://www.netlify.com/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="external"
                >
                  netlify privacy policy
                </a>
                .
              </p>
            </blockquote>
            <p>
              At this time, your personal information is not shared with any
              other third-party applications. This list may be amended from time
              to time in Center Consoles Only’s sole discretion.
            </p>
            <p>
              Except when required by law, we will not sell, distribute, or
              reveal your e-mail address(es) or other personal information
              without your consent; however, we may disclose or transfer
              personal information collected through Center Consoles Only to
              third-parties who acquire all or a portion of our business, which
              may be the result of a merger, consolidation, or purchase of all
              or a portion of our assets, or in connection with any bankruptcy
              or reorganization proceeding brought by or against us.
            </p>
            <p>
              <b>
                ANONYMOUS DATA
                <br />
              </b>
              From time to time, we may use anonymous data, which does not
              identify you alone, or when combined with data from other parties.
              This type of anonymous data may be provided to other parties for
              marketing, advertising, or other uses. Examples of this anonymous
              data may include analytics or information collected from cookies.
            </p>
            <p>
              <b>PUBLICLY VISIBLE INFORMATION</b>
              <b>
                <br />
              </b>
              If you create a user profile on Center Consoles Only or leave a
              comment on Center Consoles Only, certain information may be
              publicly visible.
            </p>
            <p>
              <b>COOKIES</b>
              <b>
                <br />
              </b>
              Center Consoles Only uses cookies to: store visitors’ preferences;
              record user-specific information on what pages users access or
              visit; ensure that visitors are not repeatedly sent the same
              banner ads; or customize our content based on visitors’ browser
              type or other information that the visitor sends. Cookies may also
              be used by third-party services, such as Google Analytics, as
              described herein.
            </p>
            <p>
              At any time, you may prevent the setting of cookies by the
              website, by using a corresponding setting of your internet browser
              and may thus permanently deny the setting of cookies. Furthermore,
              already set cookies may be deleted at any time via an Internet
              browser or other software programs. This is possible in all
              popular Internet browsers. However, if users deactivate the
              setting of cookies in your Internet browser, not all functions of
              Center Consoles Only may be entirely usable.
            </p>
            <p>
              <b>
                WHAT IF THERE ARE CHANGES TO THIS PRIVACY AND SPAM POLICY?
                <br />
              </b>
              If we change our Privacy and Spam Policy, we will post those
              changes on our privacy page at
              https://www.centerconsolesonly.net/privacy/.
            </p>
            <p>
              <b>RIGHTS RELATED TO YOUR PERSONAL DATA</b>
            </p>
            <blockquote>
              <p>
                <strong>Opt-out</strong>: You may opt-out of future e-mail
                communications by following the unsubscribe links in our
                e-mails. You may also notify us at{" "}
                <a href="mailto:support@centerconsolesonly.net">
                  support@centerconsolesonly.net
                </a>{" "}
                to be removed from our mailing list.
              </p>
              <p>
                <strong>Access</strong>: You may access the personal information
                we have about you by submitting a request to{" "}
                <a href="mailto:support@centerconsolesonly.net">
                  support@centerconsolesonly.net
                </a>
                .
              </p>
              <p>
                <strong>Amend</strong>: You may contact us at{" "}
                <a href="mailto:support@centerconsolesonly.net">
                  support@centerconsolesonly.net
                </a>{" "}
                to amend or update your personal information.
              </p>
              <p>
                <strong>Forget</strong>: In certain situations, you may request
                that we erase or forget your personal data. To do so, please
                submit a request to{" "}
                <a href="mailto:support@centerconsolesonly.net">
                  support@centerconsolesonly.net
                </a>
                .
              </p>
            </blockquote>
            <p>
              Please note that we may need to retain certain information for
              recordkeeping purposes or to complete transactions, or when
              required by law.
            </p>
            <p>
              <b>
                SENSITIVE PERSONAL INFORMATION
                <br />
              </b>
              At no time should you submit sensitive personal information to
              Center Consoles Only. This includes your social security number,
              information regarding race or ethnic origin, political opinions,
              religious beliefs, health information, criminal background, or
              trade union memberships. If you elect to submit such information
              to us, it will be subject to this Privacy and Spam Policy.
            </p>
            <p>
              <b>
                CHILDREN’S INFORMATION
                <br />
              </b>
              Center Consoles Only does not knowingly collect any personally
              identifiable information from children under the age of 16. If a
              parent or guardian believes that Center Consoles Only has
              personally identifiable information of a child under the age of 16
              in its database, please contact us immediately at{" "}
              <a href="mailto:support@centerconsolesonly.net">
                support@centerconsolesonly.net
              </a>{" "}
              and we will use our best efforts to promptly remove such
              information from our records.
            </p>
            <p>
              <b>
                SPAM POLICY
                <br />
              </b>
              Center Consoles Only is strongly opposed to spam. We will only
              send e-mail to users that have explicitly requested to receive an
              e-mail from Center Consoles Only. Examples may include:
            </p>
            <ul>
              <li>Opt-in subscribers to the Center Consoles Only Newsletter</li>
              <li>Users that complete a contact form</li>
              <li>
                All communication will be related and relevant to what you have
                requested.
              </li>
            </ul>
            <p>
              <b>
                WEB LINKS
                <br />
              </b>
              Center Consoles Only contains links to other sites. Please be
              aware that Center Consoles Only is not responsible for the privacy
              practices or the content of such other sites. We encourage our
              users to be aware when they leave our site to read the privacy and
              spam policies of each and every website that collects personally
              identifiable information. This Privacy and Spam Policy applies
              solely to information collected by Center Consoles Only.
            </p>
            <p>
              <b>
                HOW DO I CONTACT Center Consoles Only?
                <br />
              </b>
              If you have any further questions or comments regarding the Social
              Media Examiner website or this Privacy and Spam Policy, you may
              contact{" "}
              <a href="mailto:support@centerconsolesonly.net">
                support@centerconsolesonly.net
              </a>
              .
            </p>
            <p>
              <b>
                LEGAL DISCLAIMER
                <br />
              </b>
              We may disclose personal information when required by law or in
              the good-faith belief that such action is necessary in order to
              conform to the edicts of the law or comply with a legal process
              served on our site.
            </p>
          </GridItem>
        </Card>
      </GridContainer>
    </Layout>
  )
}
export default withStyles(javascriptStyles)(PrivacyPage)
